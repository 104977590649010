<template>
  <v-overlay :value="show">
    <v-card
      v-click-outside="close"
      width="1300px"
      light
      class="pb-5"
      color="#333333"
    >
      <v-card-title class="white--text d-flex justify-space-between">
        <span>Смена исполнителя</span>
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <div>
        <v-data-table
          fixed-header
          :height="500"
          :items="users"
          :headers="headers"
          hide-default-footer
          class="rounded-0"
          :item-class="isSelected"
          :items-per-page="-1"
          @click:row="selectUser"
        />
        <div class="d-flex justify-end">
          <v-btn
            dark
            color="#5CB7B1"
            class="mx-5 mt-5"
            :disabled="selected === 0"
            @click="submit"
          >
            Подтвердить
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-overlay>
</template>
<script>
import modalWindowMixin from "@/mixins/modal-window.mixin";
import { mapGetters } from 'vuex';
export default {
  mixins:[modalWindowMixin],
  props:{
    id:{
      type:Number,
      required:true,
    },
    rootUserId:{
      type:Number,
      required:true,
    },
    module: {
      type:String,
      required:true,
    }
  },
  data:() => ({
    users:[],
    selected:0,
    headers:[
      {
        text:"ID",
        value:"id"
      },
      {
        text:"ФИО",
        value:"name"
      },
      {
        text:"E-mail",
        value:"email"
      },

    ]
  }),
  computed:{
    ...mapGetters({
      user:"auth/getUser"
    })
  },
  watch:{
    show:{
      handler(nv){
        if(nv === true){
          this.fetchUsers()
        }else{
          this.selected = 0
        }
      },
      immediate:true
    }
  },
  methods:{
    isSelected(item){
      return {
        'blue lighten-4':item.id === this.selected
      }
    },
    selectUser(item){
      this.selected = item.id
    },
    fetchUsers(){
      const {user:{activeDivisionId}} = this.user
      this.$store.dispatch('auth/fetchUsersByDivision', activeDivisionId).then(res => {
        this.users = this.prepareUsers(res.data)
      })
    },
    unset(){
      this.selected = 0
    },
    prepareUsers(users) {
      return users.filter(i => i.id !== this.rootUserId).map(item => {
        const {id,email, firstName, lastName, middleName} = item
        const name = [lastName, firstName, middleName].join(' ').trim()
        return {
          id,
          email,
          name
        }
      })
    },
    submit(){

      if(this.id< 0) return false;
      this.$store.dispatch(`${this.module}/changePerformer`, {documentId:this.id, personId:this.selected}).then(() => {
        this.$snackbar({text:"Исполнитель изменен",color:"green", top:false, right: false});
        if(this.$route.params?.id > 0){ //Если детальная страница
          this.$router.push({name:"Journal"})
        }else{
          this.$store.dispatch('journal/fetchItems')
          this.unset()
          this.close()
        }
      }).catch((err) => {
        const text = err.response.status === 403 ? "Недостаточно прав": "Ошибка"
        this.$snackbar({text,color:"red", top:false, right: false});
      })
    }
  }


}
</script>
